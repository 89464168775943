@font-face {
  font-family: NunitoSans;
  src: url(../src/static/fonts/misc/NunitoSans.ttf);
  font-weight: 100 900;
  // font-stretch: 90%;
  font-stretch: semi-condensed;
}

@font-face {
  font-family: SemplicitaLight;
  src: url(../src/static/fonts/semplicita/SemplicitaLight.otf);
  font-weight: 300;
}

@font-face {
  font-family: SemplicitaMedium;
  src: url(../src/static/fonts/semplicita/SemplicitaMedium.otf);
  font-weight: 400;
}

$bgClr: #e1d1c2;
$sctClr: #f2f2f2;
$invSctClr: #b3a398;
$pnlClr: #131313;
$accClr: #faad80;
$fntClr: #2d2926;

$prmRds: 20px;

* {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  background: $invSctClr;
}

body {
  min-width: 353px;
  text-rendering: optimizeLegibility;
}

h1 {
  color: $fntClr;
  font-family: SemplicitaLight;
  font-size: 64px;
  font-weight: 300;
  line-height: 90%;
}

h2 {
  color: $fntClr;
  font-family: SemplicitaLight;
  font-size: 38px;
  font-weight: 300;
}

h3 {
  color: $fntClr;
  font-family: NunitoSans;
  font-weight: 700;
}

h4 {
  color: $fntClr;
  font-family: SemplicitaLight;
  font-size: 38px;
  font-weight: 300;
}

p {
  color: $fntClr;
  font-family: NunitoSans;
  font-size: 18px;
  font-weight: 400;
}

a,
a:visited,
a:active {
  color: #f2f2f2;
  font-family: NunitoSans;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
p,
a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
}

.navigation-container {
  display: flex;
  justify-content: center;
  min-width: 353px;
  position: fixed;
  transition: 0.5s;
  width: 100%;
  z-index: 2;
  .navigation {
    align-items: center;
    display: flex;
    height: 80px;
    left: 0;
    max-width: 1280px;
    padding: 0 40px;
    top: 0;
    width: 100%;
    div {
      display: flex;
      gap: 18px;
      img {
        cursor: pointer;
        filter: invert(100%) sepia(80%) saturate(227%) hue-rotate(272deg) brightness(112%) contrast(90%);
        transform: translateY(60px);
        transition: 0.5s;
        user-select: none;
        width: 48px;
      }
      img:hover {
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-name: navigationLogoHover;
      }
      div {
        a {
          font-size: 28px;
          transform: translateY(60px);
          transition: 0.5s;
        }
        a:hover {
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-name: navigationEntryHover;
        }
      }
    }
    .entries {
      margin: 0 0 0 auto;
    }
    .menu {
      display: none;
      margin: 0 0 0 auto;
      div {
        backdrop-filter: brightness(40%);
        height: 100vh;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        div {
          align-items: center;
          background: $pnlClr;
          border-radius: $prmRds;
          display: flex;
          flex-direction: column;
          height: 240px;
          justify-content: center;
          left: 50%;
          padding: 40px;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 200px;
          a {
            transform: none;
          }
          span {
            align-items: center;
            display: flex;
            justify-content: center;
            margin: 20px 0 0 0;
            transform: translateX(-5px);
            img {
              filter: none !important;
              transform: none;
            }
            p {
              color: #7d726a;
              font-size: 24px;
            }
          }
          span:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@keyframes navigationEntryHover {
  0% {
    color: $bgClr;
  }
  100% {
    color: $bgClr;
  }
}

@keyframes navigationLogoHover {
  0% {
    filter: invert(93%) sepia(15%) saturate(627%) hue-rotate(316deg) brightness(102%) contrast(76%);
  }
  100% {
    filter: invert(93%) sepia(15%) saturate(627%) hue-rotate(316deg) brightness(102%) contrast(76%);
  }
}

.banner {
  align-items: center;
  background: $invSctClr;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100dvh;
  padding: 0 40px;
  perspective: 300px;
  position: relative;
  text-align: center;
  white-space: nowrap;

  h1 {
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-name: glide-left;
    animation-timing-function: cubic-bezier(0.001, 0, 0.5, 1);
    color: #867070;
    // font-family: SemplicitaBold;
    font-size: 54px;
    // font-weight: 700;
    font-weight: 800;
    left: 50%;
    // letter-spacing: -1px;
    position: fixed;
  }
  h2 {
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-name: glide-right;
    animation-timing-function: cubic-bezier(0.001, 0, 0.5, 1);
    // background: #423e3b;
    // color: #b3a398;
    color: #423e3b;
    font-size: 28px;
    // font-weight: 400;
    letter-spacing: 1px;
    padding: 2px 10px;
    position: fixed;
    right: 50%;
    transform: translateY(62px);
  }
  img {
    animation-delay: 3s;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: bounce;
    transform: translateY(-100px);
    animation-timing-function: ease-in;
    bottom: 0;
    pointer-events: none;
    position: fixed;
    user-select: none;
    width: 36px;
  }
  div {
    background: $bgClr;
    bottom: 0;
    clip-path: polygon(47% 35%, 68% 20%, 84% 35%, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 0, 14% 23%, 33% 11%);
    height: 100px;
    position: absolute;
    width: 100%;
  }

  @keyframes glide-left {
    0% {
      opacity: 0;
      left: 20%;
      scale: 1.3;
    }
    0% {
      opacity: 0;
    }
    100% {
      left: 50%;
      scale: 1;
    }
  }

  @keyframes glide-right {
    0% {
      opacity: 0;
      right: 20%;
      scale: 0.7;
    }
    0% {
      opacity: 0;
    }
    100% {
      right: 50%;
      scale: 1;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(-100px);
    }
    2% {
      transform: translateY(-105px) rotate(-10deg);
    }
    4% {
      transform: translateY(-110px) rotate(10deg);
    }
    6% {
      transform: translateY(-115px) rotate(-5deg);
    }
    10% {
      transform: translateY(-115px);
    }
    13% {
      transform: translateY(-115px);
    }
    17% {
      transform: translateY(-100px);
    }
    100% {
      transform: translateY(-100px);
    }
  }
}

.feed {
  background: $bgClr;
  display: flex;
  justify-content: center;
  padding: 40px;
  position: relative;
  z-index: 1;
}

.feed-container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1280px;
}

.feed-item {
  align-items: center;
  aspect-ratio: 1;
  background: $sctClr;
  border-radius: $prmRds;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  position: relative;
  text-align: center;
  transition: 0.45s;
  h3 {
    // font-family: SemplicitaMedium;
    font-size: 16px;
    // font-weight: 500;
    position: absolute;
    top: 60px;
    transition: 0.25s;
  }
  h4 {
    margin: 0 0 14px 0;
    transition: 0.25s;
  }
  div {
    align-items: flex-end;
    bottom: 60px;
    display: flex;
    gap: 6px;
    position: absolute;
    transition: 0.25s;
    p {
      font-weight: 700;
      font-size: 16px;
    }
    img {
      transform: translate(0, 1px);
      user-select: none;
      width: 24px;
    }
  }
}

.feed-item-hover:hover {
  background: #ffffff;
  scale: 1.03;
  div {
    display: flex;
    gap: 12px;
  }
}

.inactive {
  opacity: 0.62 !important;
}

.feed-image {
  background: $accClr;
  border-radius: $prmRds;
  grid-column: 2/4;
  grid-row: 1/2;
  position: relative;
  transition: 0.45s;
  img {
    left: 50%;
    opacity: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
  }
}

.operation {
  background: $sctClr;
  display: flex;
  gap: 40px;
  justify-content: center;
  padding: 150px 40px;
  position: relative;
  z-index: 1;
  div {
    div {
      width: 480px;
      h1 {
        color: #ff6767;
        font-size: 48px;
        margin: 4px 0 16px 0;
        transition: 0.2s;
      }
      h2 {
        font-size: 26px;
      }
      div {
        max-width: 480px;
        P {
          color: $fntClr;
          font-size: 22px;
          margin: 14px 0 0 0;
        }
      }
    }
  }
  .image {
    width: 500px;
    img {
      transition: 0.25s;
      width: 100%;
    }
  }
}

.service {
  background: $bgClr;
  display: flex;
  justify-content: center;
  padding: 40px;
  position: relative;
  z-index: 1;
}

.service-container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1280px;
  width: 100%;
}

.service-item-back {
  opacity: 0;
}

.flipping-service-item {
  aspect-ratio: 1;
  background: transparent;
  perspective: 1000px;
  position: relative;
  text-align: center;
  p {
    margin: 10px 40px 0 40px;
  }
  img {
    bottom: 10px;
    left: 18px;
    position: absolute;
    width: 38px;
  }
}

.flipping-service-item-inner {
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.75s, scale 0.45s;
  width: 100%;
}

.flipping-service-item-hover:hover {
  .flipping-service-item-inner {
    scale: 1.03;

    cursor: pointer;
    .flipping-service-item-front {
      background: #ffffff;
    }
  }
}

.flipped-service-item {
  transform: rotateY(180deg);
}

.flipping-service-item-front,
.flipping-service-item-back {
  align-items: center;
  backface-visibility: hidden;
  border-radius: $prmRds;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  transition: 0.45s;
  width: 100%;
}

.flipping-service-item-front {
  background: $sctClr;
}

.flipping-service-item-back {
  background: $invSctClr;
  transform: rotateY(180deg);
  p {
    color: $sctClr;
  }
}

.service-image {
  background: $accClr;
  border-radius: $prmRds;
  grid-column: 1/3;
  grid-row: 2/2;
  position: relative;
  transition: 0.45s;
  img {
    left: 50%;
    opacity: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
  }
}

.projects-header {
  align-items: center;
  background: #1e1e1e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 180px 40px 100px 40px;
  text-align: center;
  h1 {
    color: #f2f2f2;
  }
  p {
    color: #f2f2f2;
    font-size: 19px;
    margin: 16px 0 0 0;
    max-width: 1280px;
  }
}

.projects-description {
  background: #1e1e1e;
  display: flex;
  justify-content: center;
  padding: 0 40px 0 40px;
  h2 {
    color: #787878;
    max-width: 1280px;
    width: 100%;
  }
}

.projects {
  background: #1e1e1e;
  display: flex;
  justify-content: center;
  padding: 60px 40px 90px 40px;
}

.projects-container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1280px;
}

.projects-item {
  align-items: center;
  background: #7a3476;
  border-radius: $prmRds;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 50px;
  position: relative;
  text-align: center;
  transition: 0.5s;
  h4 {
    color: #f2f2f2;
    font-size: 32px;
  }
  p {
    color: #f2f2f2;
    margin: 20px 0 25px 0;
  }
  .preview {
    display: flex;
    gap: 20px;
    width: 100%;
  }
  .preview-desktop {
    img {
      width: 100%;
      transform: perspective(1500px) rotateX(25deg);
      transition: 0.75s;
    }
    img:hover {
      transform: unset;
    }
  }
  .preview-mobile {
    img {
      width: 100%;
    }
  }
  .mobile {
    margin: 0 0 0 15px;
  }
  .icon {
    cursor: pointer;
    margin: 15px 0 0 0;
    transition: 0.25s;
    width: 38px;
  }
  .icon:hover {
    scale: 0.75;
  }
  .repo {
    position: relative;
    top: 4px;
    width: 46px;
  }
  .minor {
    margin: 0;
  }
  .project-stack-container {
    margin: 10px 0 0 0;
    div {
      display: inline-block;
      user-select: none;
      p {
        background: #61295e;
        border-radius: 6px;
        cursor: default;
        display: inline;
        display: inline;
        line-height: 51px;
        margin: 0 4px;
        padding: 10px 12px;
        white-space: nowrap;
      }
    }
    p:hover {
      background: #131313;
    }
  }
}

.contact {
  background: #7d6a6c;
  display: flex;
  gap: 30px;
  justify-content: center;
  padding: 180px 40px 150px 40px;
  img {
    max-width: 500px;
    width: 100%;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      color: $sctClr;
    }
    p {
      margin: 10px 0 0 0;
      white-space: nowrap;
      color: $sctClr;
    }
  }
}

.article-container {
  background: #7d726a;
  display: flex;
  justify-content: center;
  padding: 180px 40px 200px 40px;
  div {
    position: relative;
    width: 720px;
    h1 {
      color: #e8e3e0;
      display: block;
    }
    h2 {
      color: #e8e3e0;
      margin: 0 0 10px 0;
    }
    .deck {
      font-size: 19px;
    }
    .body {
      margin: 30px 0 20px 0;
    }
    p {
      color: #e8e3e0;
      font-size: 17px;
      margin: 0 0 20px 0;
    }
    img {
      float: left;
      margin: 0 15px 0 0;
      max-width: 320px;
      width: 100%;
    }
    .back-button {
      background: #7d726a;
      border-radius: $prmRds;
      border: 2px solid #e8e3e0;
      bottom: -80px;
      display: flex;
      // align-items: center;
      gap: 10px;
      padding: 10px 24px;
      position: absolute;
      width: fit-content;
      img {
        margin: 0;
        user-select: none;
        width: 24px;
      }
      p {
        // font-family: SemplicitaMedium;
        font-weight: 500;
        margin: 0;
      }
    }
    .back-button:hover {
      cursor: pointer;
      background: #978e87;
    }
  }
}

.footer {
  align-items: center;
  background: $pnlClr;
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: center;
  position: relative;
  z-index: 1;
  p {
    color: #f2f2f2;
  }
  div {
    display: flex;
    margin: 0 0 20px 0;
    img {
      filter: invert(100%) sepia(80%) saturate(227%) hue-rotate(272deg) brightness(112%) contrast(90%);
      user-select: none;
      width: 48px;
    }
    img:hover {
      filter: invert(93%) sepia(15%) saturate(627%) hue-rotate(316deg) brightness(102%) contrast(76%);
      cursor: pointer;
    }
    .github-icon {
      bottom: 2px;
      position: relative;
      width: 56px;
    }
  }
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.75s ease-out, transform 0.75s ease-out;
}

.in-view {
  opacity: 1;
  transform: translateY(0);
}

*:focus {
  outline: none;
}

@media (pointer: coarse) {
  :focus {
    outline: none;
  }
}

@media (min-width: 1100px) and (max-width: 1268px) {
  .service-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .service-image {
    grid-column: 1/3;
    grid-row: 1/2;
  }
}

@media (min-width: 950px) and (max-width: 1100px) {
  .feed-item {
    h3 {
      position: absolute;
      top: 40px;
    }
    div {
      bottom: 40px;
    }
  }
  .service-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .service-image {
    grid-column: 1/3;
    grid-row: 1/2;
  }
}

@media (min-width: 920px) and (max-width: 950px) {
  .feed-item {
    h3 {
      top: 30px;
    }
    div {
      bottom: 20px;
    }
  }
  .service-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .service-image {
    grid-column: 1/3;
    grid-row: 1/2;
  }
}

@media (min-width: 900px) and (max-width: 920px) {
  .feed-item {
    h3 {
      opacity: 0;
    }
    div {
      scale: 0.85;
      bottom: 20px;
    }
    h4 {
      font-size: 32px;
      margin: 0 0 10px 0;
    }
  }
  .service-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .service-image {
    grid-column: 1/3;
    grid-row: 1/2;
  }
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 880px) and (max-width: 900px) {
  .feed-item {
    h3 {
      opacity: 0;
    }
    div {
      scale: 0.85;
      bottom: 20px;
    }
    h4 {
      font-size: 32px;
      margin: 0 0 10px 0;
    }
  }
  .service-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .service-image {
    grid-column: 1/3;
    grid-row: 1/2;
  }
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .service-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .service-image {
    grid-column: 1/2;
    grid-row: 2/4;
  }
}

@media (min-width: 830px) and (max-width: 880px) {
  .feed-item {
    h3 {
      opacity: 0;
    }
    div {
      bottom: 20px;
      scale: 0.85;
      transition: gap 0.45s;
    }
    h4 {
      font-size: 32px;
      margin: 0 0 10px 0;
    }
  }
  .operation {
    div {
      div {
        width: 100%;
      }
    }
    .image {
      max-width: 260px;
      img {
        width: 100%;
      }
    }
  }
  .service-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .service-image {
    grid-column: 1/2;
    grid-row: 2/4;
  }
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 760px) and (max-width: 830px) {
  .feed-item {
    h3 {
      opacity: 1;
    }
    div {
      bottom: 60px;
      scale: 1;
    }
    h4 {
      font-size: 38px;
    }
  }
  .feed-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .feed-image {
    grid-column: 2/3;
    grid-row: 4/2;
  }
  .operation {
    div {
      div {
        width: 100%;
      }
    }
    .image {
      max-width: 260px;
      img {
        width: 100%;
      }
    }
  }
  .service-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .service-image {
    grid-column: 1/2;
    grid-row: 2/4;
  }
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 670px) and (max-width: 760px) {
  .feed-item {
    h3 {
      position: absolute;
      top: 40px;
    }
    div {
      bottom: 40px;
    }
  }
  .feed-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .feed-image {
    grid-column: 2/3;
    grid-row: 4/2;
  }
  .operation {
    flex-direction: column;
    div {
      div {
        width: 100%;
        div {
          max-width: unset;
        }
      }
    }
    .image {
      max-width: unset;
      width: 480px;
      img {
        width: 100%;
      }
    }
  }
  .service-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .service-image {
    grid-column: 1/2;
    grid-row: 2/4;
  }
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 640px) and (max-width: 670px) {
  .feed-item {
    h3 {
      top: 30px;
    }
    div {
      bottom: 20px;
    }
  }
  .feed-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .feed-image {
    grid-column: 2/3;
    grid-row: 4/2;
  }
  .operation {
    flex-direction: column;
    div {
      div {
        width: 100%;
        div {
          max-width: unset;
        }
      }
    }
    .image {
      max-width: 480px;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .service-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .service-image {
    grid-column: 1/2;
    grid-row: 2/4;
  }
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .article-container {
    div {
      img {
        float: unset;
        margin: 10px 0 0 0;
      }
    }
  }
}

@media (min-width: 580px) and (max-width: 640px) {
  .feed-item {
    h3 {
      opacity: 0;
    }
    div {
      bottom: 20px;
      scale: 0.85;
      transition: gap 0.45s;
    }
    h4 {
      font-size: 32px;
      margin: 0 0 10px 0;
    }
  }
  .feed-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .feed-image {
    grid-column: 2/3;
    grid-row: 4/2;
  }
  .operation {
    flex-direction: column;
    div {
      div {
        width: 100%;
        div {
          max-width: unset;
        }
      }
    }
    .image {
      max-width: 480px;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .service-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .service-image {
    grid-column: 1/2;
    grid-row: 2/4;
  }
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .article-container {
    div {
      img {
        float: unset;
        margin: 10px 0 0 0;
      }
    }
  }
}

@media (min-width: 420px) and (max-width: 580px) {
  .feed-item {
    h3 {
      opacity: 1;
    }
    div {
      scale: 1;
      bottom: 60px;
    }
    h4 {
      font-size: 38px;
    }
  }
  .feed-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .feed-image {
    display: none;
  }
  .operation {
    flex-direction: column;
    div {
      div {
        width: 100%;
        div {
          max-width: unset;
        }
      }
    }
    .image {
      max-width: 480px;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .service-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .service-image {
    display: none;
  }
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
    .projects-item {
      h4 {
        font-size: 24px;
      }
    }
  }
  .contact {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .article-container {
    div {
      img {
        float: unset;
        margin: 10px 0 0 0;
      }
    }
  }
  .navigation {
    padding: 0 20px !important;
  }
  .feed,
  .service {
    display: block;
    padding: 20px;
  }
  .operation {
    padding: 130px 20px;
  }
  .projects-header {
    padding: 200px 20px 100px 20px;
  }
  .projects {
    padding: 60px 20px 90px 20px;
  }
  .contact {
    padding: 200px 20px 130px 20px;
  }
  .article-container {
    padding: 200px 20px 200px 20px;
  }
  .projects-item {
    padding: 34px 24px;
    .preview-desktop {
      img {
        transform: none;
      }
    }
  }
  .entries {
    display: none !important;
  }
  .menu {
    display: unset !important;
  }
}

@media (min-width: 368px) and (max-width: 420px) {
  .feed-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .feed-image {
    display: none;
  }
  .operation {
    flex-direction: column;
    div {
      div {
        width: 100%;
        div {
          max-width: unset;
        }
      }
    }
    .image {
      max-width: 480px;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .feed-item {
    h3 {
      position: absolute;
      top: 40px;
    }
    div {
      bottom: 40px;
    }
  }
  .service-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .service-image {
    display: none;
  }
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
    .projects-item {
      h4 {
        font-size: 24px;
      }
    }
  }
  .contact {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .article-container {
    div {
      img {
        float: unset;
        margin: 10px 0 0 0;
      }
    }
  }
  .navigation {
    padding: 0 20px !important;
  }
  .feed,
  .service {
    display: block;
    padding: 20px;
  }
  .operation {
    padding: 130px 20px;
  }
  .projects-header {
    padding: 200px 20px 100px 20px;
  }
  .projects {
    padding: 60px 20px 90px 20px;
  }
  .contact {
    padding: 200px 20px 130px 20px;
  }
  .article-container {
    padding: 200px 20px 200px 20px;
  }
  .projects-item {
    padding: 34px 24px;
    .preview-desktop {
      img {
        transform: none;
      }
    }
  }
  .entries {
    display: none !important;
  }
  .menu {
    display: unset !important;
  }
}

@media (min-width: 0px) and (max-width: 368px) {
  .feed-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .feed-image {
    display: none;
  }
  .operation {
    flex-direction: column;
    div {
      div {
        width: 100%;
        div {
          max-width: unset;
        }
      }
    }
    .image {
      width: 100%;
      max-width: 480px;
      img {
        width: 100%;
      }
    }
  }
  .feed-item {
    h3 {
      position: absolute;
      top: 40px;
    }
    div {
      bottom: 40px;
    }
  }
  .service-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .service-image {
    display: none;
  }
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
    .projects-item {
      h4 {
        font-size: 24px;
      }
    }
  }
  .contact {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .article-container {
    div {
      img {
        float: unset;
        margin: 10px 0 0 0;
      }
    }
  }
  .navigation {
    padding: 0 20px !important;
  }
  .feed,
  .service {
    display: block;
    padding: 20px;
  }
  .operation {
    padding: 130px 20px;
  }
  .projects-header {
    padding: 200px 20px 100px 20px;
  }
  .projects {
    padding: 60px 20px 90px 20px;
  }
  .contact {
    padding: 200px 20px 130px 20px;
  }
  .article-container {
    padding: 200px 20px 200px 20px;
  }
  .projects-item {
    padding: 34px 24px;
    .preview-desktop {
      img {
        transform: none;
      }
    }
  }
  .entries {
    display: none !important;
  }
  .menu {
    display: unset !important;
  }
}
